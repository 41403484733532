import { render, staticRenderFns } from "./addWasteToLicenseForm.vue?vue&type=template&id=48929ff8&scoped=true&"
import script from "./addWasteToLicenseForm.vue?vue&type=script&lang=js&"
export * from "./addWasteToLicenseForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48929ff8",
  null
  
)

export default component.exports