<template>
  <div>
    <h2>Данные о природопользователе</h2>
    <el-button type="warning" v-if="!edit" @click="edit = true"
      >Редактировать</el-button
    >
    <el-button type="warning" v-else @click="edit = false">Отмена</el-button>
    <el-form
      :rules="rules"
      :model="natResourcesForm"
      ref="formedForm"
      label-position="top"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item prop="companyName" label="Полное наименование:">
            <el-input
              v-if="edit"
              v-model="organizationInfo.egrDTO.companyName"
            ></el-input>
            <b v-else>{{ organizationInfo.egrDTO.companyName }}</b>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item prop="unp" label="УНП:">
            <el-input
              v-if="edit"
              v-model="organizationInfo.egrDTO.egrId"
            ></el-input>
            <b v-else>{{
              organizationInfo.egrDTO.egrId !== null
                ? organizationInfo.egrDTO.egrId
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            prop="address"
            label="Юридический адрес природопользователя:"
          >
            <el-input
              v-if="edit"
              v-model="organizationInfo.egrDTO.address"
            ></el-input>
            <b v-else>{{
              organizationInfo.egrDTO.address !== null
                ? organizationInfo.egrDTO.address
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <h4>Фактический адрес нахождения подразделения</h4>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="region" label="Область:">
            <el-select
              v-if="edit"
              class="searchPole"
              @change="changeArea"
              style="width: 100%"
              clearable
              value-key="id"
              v-model="organizationInfo.areaInfo"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in areaNames.content"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <b v-else>{{
              organizationInfo.areaInfo.name !== null
                ? organizationInfo.areaInfo.name
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="district" label="Район:">
            <el-select
              clearable
              v-if="edit"
              @change="changeDistrict"
              :disabled="organizationInfo.areaInfo.name === null"
              class="searchPole"
              v-model="organizationInfo.districtInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Район"
              :remote-method="searchDistrictNames"
            >
              <el-option
                v-for="item in districtNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <b v-else>{{
              organizationInfo.districtInfo.name !== null
                ? organizationInfo.districtInfo.name
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="locality" label="Населенный пункт:">
            <el-select
              clearable
              v-if="edit"
              :disabled="organizationInfo.districtInfo.name === null"
              class="searchPole"
              v-model="organizationInfo.cityInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Район"
              :remote-method="searchCitiesNames"
            >
              <el-option
                v-for="item in citiesNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <b v-else>{{
              organizationInfo.cityInfo.name !== null
                ? organizationInfo.cityInfo.name
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="street" label="Улица:">
            <el-input v-if="edit" v-model="organizationInfo.street"></el-input>
            <b v-else>{{
              organizationInfo.street !== null
                ? organizationInfo.street
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item prop="phoneNumber" label="Телефон, факс приемной:">
            <el-input
              v-if="edit"
              v-model="organizationInfo.phoneNumber"
            ></el-input>
            <b v-else>{{
              organizationInfo.phoneNumber !== null
                ? organizationInfo.phoneNumber
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            prop="email"
            label="Электронный адрес природопользователя:"
          >
            <el-input v-if="edit" v-model="organizationInfo.email"></el-input>
            <b v-else>{{
              organizationInfo.email !== null
                ? organizationInfo.email
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="activity"
            label="Основной вид экономической деятельности по ОКЭД:"
          >
            <el-select
              clearable
              v-if="edit"
              class="searchPole"
              filterable
              v-model="organizationInfo.economyActivityInfo"
              value-key="id"
              placeholder=""
            >
              <el-option
                v-for="item in economySubClassifier"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <b v-else>{{
              organizationInfo.economyActivityInfo.name !== null
                ? organizationInfo.economyActivityInfo.name
                : "Сведения отсутствуют"
            }}</b>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-button v-if="edit" @click="save" type="primary"
            >Сохранить изменения</el-button
          >
        </el-col>
      </el-row>
    </el-form>

    <el-divider></el-divider>

    <el-tabs v-model="activeName">
      <el-tab-pane lazy label="Добавленные лицензии" name="addedLicense">
        <nat-resource-added-license></nat-resource-added-license>
      </el-tab-pane>
      <el-tab-pane label="Добавленные разрешения" name="addedPermissions">
        <nat-resource-added-permission></nat-resource-added-permission>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NatResourceAddedPermission from "@/components/ecologist/natResources/natResourceAddedPermission";
import NatResourceAddedLicense from "@/components/ecologist/natResources/natResourceAddedLicense";
import notification from "@/mixins/notification";
export default {
  name: "natResourcesMain",
  mixins: [notification],
  components: {
    NatResourceAddedLicense,
    NatResourceAddedPermission,
  },
  data() {
    return {
      edit: false,
      activeName: "addedLicense",
      natResourcesForm: {
        fullName: "",
        unit: "",
        unp: "",
        address: "",
        region: "",
        district: "",
        locality: "",
        street: "",
        phoneNumber: "",
        email: "",
        activity: "",
      },

      rules: {},
    };
  },

  computed: {
    ...mapGetters({
      organizationInfo: "GETORGANIZATIONINFO",
      areaNames: "GETLISTALLAREAS",
      districtNames: "GETLISTDISTRICNAMES",
      citiesNames: "GETLISTCITIESNAMES",
      economySubClassifier: "GETECONOMYSUBCLASSIFIER",
    }),
  },

  methods: {
    searchResponsiblePerson(query) {
      this.searchResponsiblePersonByQuery(query);
    },

    searchDistrictNames(query) {
      this.getDistrictNames(query);
    },

    searchCitiesNames(query) {
      this.getCitiesNames(query);
    },

    changeArea() {
      this.searchDistrictNames("");
    },

    changeDistrict() {
      this.searchCitiesNames("");
    },

    getListAllAreas() {
      this.$store.dispatch("getListAllAreas", {
        page: 0,
        size: 20,
      });
    },

    getDistrictNames(query) {
      this.$store.dispatch("getDistrictNames", {
        areaId: this.organizationInfo.areaInfo.id,
        query: query,
      });
    },

    getCitiesNames(query) {
      this.$store.dispatch("getCitiesNamesForUser", {
        areaId: this.organizationInfo.areaInfo.id,
        districtId: this.organizationInfo.districtInfo.id,
        query: query,
      });
    },

    searchResponsiblePersonByQuery(query) {
      this.$store.dispatch("searchResponsiblePerson", {
        page: 0,
        size: 20,
        query: query,
      });
    },

    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 1,
      });
    },

    save() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          if(this.organizationInfo.egrDTO.egrId == "") this.organizationInfo.egrDTO.egrId=0;
          this.$store
            .dispatch("updateOrganizationInfo", this.organizationInfo)
            .then((response) => {
              this.edit = false;
              this.notification("Успешно", "Изменения сохранены", "success");
              console.log(response);
            });
        }
      });
    },

    async getOrganizationInfo() {
      await this.$store.dispatch("getOrganizationInfo");
    },

    getEconomySubClassifier() {
      this.$store.dispatch("getEconomySubClassifier");
    },
  },

  async mounted() {
    this.getListAllAreas();
    await this.getOrganizationInfo();
    await this.$store.dispatch("getDistrictNames", {
      areaId: this.organizationInfo.areaInfo.id,
      query: this.organizationInfo.districtInfo.name,
    });
    await this.$store.dispatch("getCitiesNamesForUser", {
      areaId: this.organizationInfo.areaInfo.id,
      districtId: this.organizationInfo.districtInfo.id,
      query: this.organizationInfo.cityInfo.name,
    });
    this.getEconomySubClassifier();
    await this.$store.dispatch("setShowModal", "updateSubDivision");
  },
};
</script>

<style scoped></style>
