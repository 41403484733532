<template>
  <div>
    <el-form
      :rules="rules"
      :model="editLicense"
      ref="formedForm"
      label-position="top"
      label-width="200px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="licenceActionInfo"
            label="Лицензируемый вид деятельности:"
          >
            <el-select
              clearable
              style="width: 100%"
              class="searchPole"
              value-key="id"
              v-model="editLicense.licenceActionInfo"
              filterable
              placeholder="Вид деятельности"
            >
              <el-option
                v-for="code in listLicenseAction"
                :key="code.id"
                :label="code.name"
                :value="code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="licenseNumber" label="Номер/Лицензия:">
            <el-input v-model="editLicense.licenseNumber"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="registrationNumber"
            label="Номер регистрации лицензии:"
          >
            <el-input v-model="editLicense.registrationNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="issuingAuthority"
            label="Орган, выдавший документ:"
          >
            <el-input v-model="editLicense.issuingAuthority"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Срок действия</h4>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            prop="validityStart"
            label="Дата начала действия лицензии:"
          >
            <el-date-picker
              style="width: 100%"
              v-model="editLicense.validityStart"
              value-format="yyyy-MM-dd"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="validityEnd"
            label="Дата окончания действия лицензии:"
          >
            <el-date-picker
              style="width: 100%"
              v-model="editLicense.validityEnd"
              value-format="yyyy-MM-dd"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="dateOfChanges"
            label="Дата внесения изменений и/или дополнений:"
          >
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="editLicense.dateOfChanges"
              type="date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-button @click="updateLicense" type="primary">Сохранить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "editLicenseForm",
  props: ["editLicense"],
  data() {
    return {
      rules: {
        licenceActionInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        licenseNumber: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        registrationNumber: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        issuingAuthority: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        validityStart: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        validityEnd: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      organizations: "GETNATORGANIZATIONS",
      listLicenseAction: "GETLISTLICENSEACTION",
    }),
  },

  methods: {
    updateLicense() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("updateLicense", this.editLicense)
            .then((response) => {
              console.log(response);
              if (response.data.licenceId !== undefined) {
                this.notification("Успешно", "Лицензия добавлена", "success");
                this.$store.dispatch("setShowModal", null);
                this.$store.dispatch("getListAllLicenses");
              }
            });
        }
      });
    },

    getListLicenseActionData() {
      this.$store.dispatch("getListLicenseActionData");
    },
  },

  mounted() {
    this.editLicense.validity = [];
    this.editLicense.validity.push(this.editLicense.validityStart);
    this.editLicense.validity.push(this.editLicense.validityEnd);
    this.getListLicenseActionData();
  },
};
</script>

<style scoped></style>
