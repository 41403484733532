<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-button @click="addPermission" type="primary"
          >Добавить разрешение</el-button
        >
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <el-table
      :row-class-name="tableRowClassName"
      stripe
      border
      :data="permissionList"
    >
      <el-table-column label="Номер разрешения" prop="permissionNumber">
      </el-table-column>

      <el-table-column label="Вид разрешения" prop="permissionType">
      </el-table-column>

      <el-table-column label="Выдавший орган" prop="issuingAuthority">
      </el-table-column>

      <el-table-column label="Дата начала" prop="validityStart">
      </el-table-column>

      <el-table-column label="Дата окончания" prop="validityEnd">
      </el-table-column>

      <el-table-column label="Дата изменения" prop="dateOfChanges">
      </el-table-column>

      <el-table-column width="150" label="">
        <template slot-scope="raw">
          <el-row>
            <el-col :span="24">
              <el-button
                style="width: 100%; margin-left: 0"
                @click="edit(raw.row)"
                type="warning"
                size="mini"
                >Редактировать</el-button
              >
              <el-button
                style="width: 100%; margin-left: 0; margin-top: 5px"
                @click="deletePermission(raw.row)"
                type="danger"
                size="mini"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <custom-modal
      title="Добавить разрешение"
      :visible="showModal === 'addPermission'"
    >
      <nat-resource-add-permission></nat-resource-add-permission>
    </custom-modal>

    <custom-modal
      title="Изменить разрешение"
      :visible="showModal === 'updatePermission'"
    >
      <edit-permission-form
        :update-permission-form="selectedPermission"
      ></edit-permission-form>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditPermissionForm from "@/components/ecologist/natResources/editPermissionForm";
import CustomModal from "@/components/customModal";
import NatResourceAddPermission from "@/components/ecologist/natResources/natResourceAddPermission";
import notification from "@/mixins/notification";

export default {
  name: "natResourceAddedPermission",
  mixins: [notification],
  components: { NatResourceAddPermission, CustomModal, EditPermissionForm },
  data() {
    return {
      selectedPermission: {},
      editPermissionModal: false,
      addedPermission: [
        {
          number: "",
          type: "",
          agency: "",
          dateEnd: "",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      permissionList: "GETPERMISSIONLIST",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(rowIndex);
      console.log(row);
      if (row.overDueFlag === 1) {
        return "warning-row";
      } else {
        return "";
      }
    },

    addPermission() {
      this.$store.dispatch("setShowModal", "addPermission");
    },

    deletePermission(item) {
      this.confirm(
        "Удалить",
        "Удалить разрешение?",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deletePermission", item.permissionId)
          .then((response) => {
            console.log(response);
            if (response.data === "DELETED") {
              this.notification("Успешно", "Разрешение удалено", "success");
              this.getListAllPermissions();
            }
          });
      });
      console.log(item);
    },

    async edit(item) {
      console.log(item);
      item.validity = [];
      item.validity.push(item.validityStart);
      item.validity.push(item.validityEnd);
      this.selectedPermission = item;
      this.$store.dispatch("setShowModal", "updatePermission");
    },

    getListAllPermissions() {
      this.$store.dispatch("getListAllPermissions");
    },
  },

  mounted() {
    this.getListAllPermissions();
  },
};
</script>

<style>
.el-table .warning-row {
  background: #ffe9e9;
}
</style>
