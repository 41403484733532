<template>
  <div>
    <search-block title="отхода">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select
            clearable
            :disabled="ss01Dictionary.content.length <= 1"
            @change="searchWasteByCode"
            class="searchPole"
            v-model="search.code"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Код отхода"
            :remote-method="searchCode"
            :loading="search.loadingCodes"
          >
            <el-option
              v-for="item in ss01CodeList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            clearable
            :disabled="ss01Dictionary.content.length <= 1"
            @change="searchWasteByName"
            class="searchPole"
            v-model="search.name"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Наименование"
            :remote-method="searchWasteName"
            :loading="search.loadingNames"
          >
            <el-option
              v-for="item in wasteNames"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select
            clearable
            :disabled="ss01Dictionary.content.length <= 1"
            @change="searchWasteByDangerDegree"
            class="searchPole"
            v-model="search.dangerDegree"
            filterable
            remote
            value-key="id"
            reserve-keyword
            placeholder="Степень опасности"
          >
            <el-option
              v-for="item in dangerDegree.content"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button @click="clearSearch" type="success">Сброс</el-button>
        </el-col>
        <el-col :span="4">
          <el-button @click="addWaste" type="success">Сохранить</el-button>
        </el-col>
      </el-row>
    </search-block>
    <el-table
      @selection-change="handleSelectionChange"
      stripe
      border
      :data="ss01Dictionary.content"
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="wasteCode" label="Код отхода"> </el-table-column>
      <el-table-column prop="wasteName" label="Наименование"> </el-table-column>
      <el-table-column
        prop="dangerDegreeClassifierDTO.name"
        label="Степень опасности и класс опасности"
      >
      </el-table-column>
      <el-table-column
        prop="bazelConvinceWasteCode"
        label="Код вида отхода по классификации"
      >
      </el-table-column>
      <el-table-column
        prop="wasteCodeByDecision2000"
        label=" Код отхода по Решению Совета 2000/532/EC"
      >
      </el-table-column>
      <el-table-column
        prop="dangerWasteDisposalPSS06DTO.name"
        label="Сделка об отчуждении опасных отходов"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="ss01Dictionary.size"
      :current-page.sync="ss01Dictionary.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="ss01Dictionary.totalElements"
    ></el-pagination>
  </div>
</template>

<script>
import SearchBlock from "@/components/searchBlock";
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";
export default {
  mixins: [notification],
  props: ["licenseId"],
  name: "addWasteToLicenseForm",
  components: { SearchBlock },
  data() {
    return {
      multipleSelection: [],
      updatedClassifier: {},
      search: {
        code: "",
        loadingCodes: false,
        name: "",
        loadingNames: false,
        dangerDegree: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      ss01Dictionary: "GETSS01DICTIONARY",
      ss01CodeList: "GETSSS01CODELIST",
      wasteNames: "GETWASTENAMES",
      dangerDegree: "GETSUBCLASSIFIERDATA",
    }),

    getPaginationOptions: {
      get() {
        return {
          page: this.ss01Dictionary.number,
          size: this.ss01Dictionary.size,
        };
      },
    },
  },

  methods: {
    addWaste() {
      let wastesId = this.multipleSelection.map((item) => {
        return item.ss01DictionaryId;
      });
      let data = {
        licenseId: this.licenseId,
        wastesId: wastesId,
      };
      if (wastesId.length !== 0) {
        this.$store.dispatch("addWasteToLicense", data).then((response) => {
          console.log(response);
          if (response.data === "Отход добавлен") {
            this.notification("Успешно", "Отход добавлен", "success");
            this.$store.dispatch("getListAllLicenses");
          }
        });
      } else {
        this.notification("Ошибка", "Выберите отход", "warning");
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    clearSearch() {
      this.getSs01dictionary({ page: 0, size: 20 });
      this.search = {
        code: "",
        loadingCodes: false,
        name: "",
        loadingNames: false,
        dangerDegree: "",
      };
    },

    handleSizeChange(val) {
      this.getSs01dictionary({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getSs01dictionary({ page: val - 1, size: this.ss01Dictionary.size });
    },

    searchCode(query) {
      this.getCodeList(query);
    },

    searchWasteName(query) {
      this.getWasteNames(query);
    },

    searchWasteByCode() {
      this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.code.id
      );
      this.search.dangerDegree =
        this.ss01Dictionary.content[0].dangerDegreeClassifierDTO;
    },

    searchWasteByName() {
      this.$store.dispatch(
        "findSS01DictionaryValueByClassifierId",
        this.search.name.id
      );
      this.search.dangerDegree =
        this.ss01Dictionary.content[0].dangerDegreeClassifierDTO;
    },

    searchWasteByDangerDegree() {
      console.log(this.search.dangerDegree);
      let data = {
        dangerDegreeId: this.search.dangerDegree.id,
        page: this.getPaginationOptions.page,
        size: this.getPaginationOptions.size,
      };

      this.$store.dispatch("findSS01DictionaryValueByDangerDegreeId", data);
    },

    async getSs01dictionary(data) {
      await this.$store.dispatch("getSs01Dictionary", data);
    },

    async getCodeList(query) {
      this.search.loadingCodes = true;
      await this.$store.dispatch("getCodeList", query);
      this.search.loadingCodes = false;
    },

    async getWasteNames(query) {
      this.search.loadingNames = true;
      await this.$store.dispatch("getWasteNames", query);
      this.search.loadingNames = false;
    },

    getSubClassifierById() {
      let data = {
        id: 1,
        size: 20,
        page: 0,
      };
      this.$store.dispatch("getAllDataSubClassifier", data);
    },
  },

  async mounted() {
    await this.getSs01dictionary({ page: 0, size: 20 });
    await this.getSubClassifierById();
  },
};
</script>

<style scoped></style>
