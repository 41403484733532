<template>
  <div>
    <nat-resources-main></nat-resources-main>
  </div>
</template>

<script>
import NatResourcesMain from "@/components/ecologist/natResources/natResourcesMain";
export default {
  name: "NatResourcesMainView",
  components: { NatResourcesMain },
};
</script>

<style scoped></style>
