<template>
  <div>
    <el-form
      :rules="rules"
      :model="addPermissionForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item prop="permissionType" label="Вид разрешения">
            <el-select
              style="width: 100%"
              class="searchPole"
              v-model="addPermissionForm.permissionType"
              filterable
              placeholder="Вид разрешения"
            >
              <el-option
                v-for="(item, index) in permissionTypes"
                :key="index"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="permissionNumber" label="Номер разрешения:">
            <el-input
              placeholder="Номер разрешения"
              v-model="addPermissionForm.permissionNumber"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="issuingAuthority"
            label="Орган, выдавший документ:"
          >
            <el-input
              placeholder="Номер разрешения"
              v-model="addPermissionForm.issuingAuthority"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Срок действия</h4>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item
            prop="validity"
            label="Дата начала и окончания действия разрешения:"
          >
            <el-date-picker
              style="width: 100%"
              v-model="addPermissionForm.validity"
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="dateOfChanges"
            label="Дата внесения изменений и/или дополнений:"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="addPermissionForm.dateOfChanges"
              type="date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-button @click="addPermission" type="primary">Добавить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import notification from "@/mixins/notification";

export default {
  mixins: [notification],
  name: "natResourceAddPermission",
  data() {
    return {
      permissionTypes: ["Хранение и захоронение отходов производства"],
      addPermissionForm: {
        permissionType: null,
        permissionNumber: null,
        issuingAuthority: null,
        validity: [],
        validityStart: null,
        validityEnd: null,
        dateOfChanges: null,
      },

      rules: {
        permissionType: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        permissionNumber: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        issuingAuthority: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        validityStart: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        validityEnd: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    addPermission() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.addPermissionForm.validityStart =
            this.addPermissionForm.validity[0];
          this.addPermissionForm.validityEnd =
            this.addPermissionForm.validity[1];
          this.$store
            .dispatch("createPermission", this.addPermissionForm)
            .then((response) => {
              console.log(response);
              if (response.data.permissionId !== undefined) {
                this.notification("Успешно", "Разрешение добавлено", "success");
                this.$store.dispatch("getListAllPermissions");
                this.$store.dispatch("setShowModal", null);
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
