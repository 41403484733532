<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-button @click="addLicense" type="primary"
          >Добавить лицензию</el-button
        >
      </el-col>
    </el-row>
    <el-divider></el-divider>

    <el-table
      :row-class-name="tableRowClassName"
      stripe
      border
      :data="licenseList"
    >
      <el-table-column
        label="Номер лицензии"
        prop="licenseNumber"
      ></el-table-column>

      <el-table-column
        label="Номер регистрации"
        prop="registrationNumber"
      ></el-table-column>

      <el-table-column
        label="Выдавший орган"
        prop="issuingAuthority"
      ></el-table-column>

      <el-table-column
        label="Дата окончания действия"
        prop="validityEnd"
      ></el-table-column>

      <el-table-column
        width="500"
        label="Добавленные отходы"
        prop="licenseNumber"
      >
        <template slot-scope="scope">
          <ul style="padding-right: 5px !important">
            <li :key="index" v-for="(item, index) in scope.row.wastesList">
              <el-row>
                <el-col :span="22"> {{ item.name }}</el-col>
                <el-col :span="2">
                  <el-button
                    @click="deleteWaste(scope.row, item.id)"
                    style="padding: 7px"
                    type="danger"
                    size="mini"
                  >
                    <i class="el-icon-close"></i>
                  </el-button>
                </el-col>
              </el-row>
            </li>
          </ul>
        </template>
      </el-table-column>

      <el-table-column width="150" label="">
        <template slot-scope="raw">
          <el-row>
            <el-col :span="24">
              <el-button
                style="width: 100%"
                size="mini"
                @click="editLicense(raw)"
                type="warning"
              >
                Редактировать
              </el-button>
              <el-button
                @click="addWastes(raw)"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                size="mini"
                type="primary"
              >
                Добавить отход
              </el-button>
              <el-button
                style="width: 100%; margin-left: 0; margin-top: 5px"
                @click="deleteLicense(raw)"
                size="mini"
                type="danger"
              >
                Удалить
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <custom-modal
      :width="90"
      :title="'Изменить лицензию'"
      :visible="showModal === 'updateLicense'"
    >
      <edit-license-form :edit-license="selectedLicense"></edit-license-form>
    </custom-modal>

    <custom-modal
      :title="'Добавить лицензию'"
      :visible="showModal === 'addLicense'"
    >
      <nat-resource-add-license></nat-resource-add-license>
    </custom-modal>

    <el-dialog title="Добавить отход" :visible.sync="dialogVisible" width="70%">
      <add-waste-to-license-form
        :licenseId="selectedLicense.licenceId"
      ></add-waste-to-license-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditLicenseForm from "@/components/ecologist/natResources/editLicenseForm";
import CustomModal from "@/components/customModal";
import NatResourceAddLicense from "@/components/ecologist/natResources/natResourceAddLicense";
import AddWasteToLicenseForm from "@/components/ecologist/natResources/addWasteToLicenseForm";
import notification from "@/mixins/notification";

export default {
  name: "natResourceAddedLicense",
  mixins: [notification],
  components: {
    AddWasteToLicenseForm,
    NatResourceAddLicense,
    CustomModal,
    EditLicenseForm,
  },
  data() {
    return {
      selectedLicense: "",
      editLicenseModal: false,
      dialogVisible: false,
      natResourceLicense: [
        {
          number: "",
          numberRegistration: "",
          issuingAuthority: "",
          dataEnd: "",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      licenseList: "GETLICENSELIST",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(rowIndex);
      console.log(row);
      if (row.overDueFlag === 1) {
        return "warning-row";
      } else {
        return "";
      }
    },

    deleteWaste(item, wasteId) {
      console.log(item);
      this.$store
        .dispatch("deleteWasteFromLicense", {
          licenceId: item.licenceId,
          wasteId: wasteId,
        })
        .then(() => {
          this.notification("Успешно", "Отход удален из лицензии", "success");
          this.getLicenseList();
        });
    },

    deleteLicense(item) {
      console.log(item);
      this.$store.dispatch("deleteLicense", item.row.licenceId);
      this.getLicenseList();
    },

    editLicense(item) {
      this.selectedLicense = item.row;
      this.$store.dispatch("setShowModal", "updateLicense");
      console.log(item);
    },

    addLicense() {
      this.$store.dispatch("setShowModal", "addLicense");
    },

    addWastes(item) {
      this.selectedLicense = item.row;
      this.dialogVisible = true;
    },

    getLicenseList() {
      this.$store.dispatch("getListAllLicenses");
    },
  },

  mounted() {
    this.getLicenseList();
  },
};
</script>

<style>
.el-table .warning-row {
  background: #ffe9e9;
}
</style>
