<template>
  <div>
    <el-form
      :rules="rules"
      :model="addLicenseForm"
      ref="formedForm"
      label-position="top"
      label-width="200px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="licenceActionInfo"
            label="Лицензируемый вид деятельности:"
          >
            <el-select
              clearable
              style="width: 100%"
              class="searchPole"
              value-key="id"
              v-model="addLicenseForm.licenceActionInfo"
              filterable
              placeholder="Вид деятельности"
            >
              <el-option
                v-for="code in listLicenseAction"
                :key="code.id"
                :label="code.name"
                :value="code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="licenseNumber" label="Номер/Лицензия:">
            <el-input v-model="addLicenseForm.licenseNumber"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="registrationNumber"
            label="Номер регистрации лицензии:"
          >
            <el-input v-model="addLicenseForm.registrationNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="issuingAuthority"
            label="Орган, выдавший документ:"
          >
            <el-input v-model="addLicenseForm.issuingAuthority"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Срок действия</h4>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item
            prop="validity"
            label="Дата начала и окончания действия лицензии:"
          >
            <el-date-picker
              style="width: 100%"
              v-model="addLicenseForm.validity"
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="dateOfChanges"
            label="Дата внесения изменений и/или дополнений:"
          >
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="addLicenseForm.dateOfChanges"
              type="date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-button @click="addLicense" type="primary"
            >Добавить лицензию</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  mixins: [notification],
  name: "natResourceAddLicense",
  data() {
    return {
      addLicenseForm: {
        licenseNumber: null,
        registrationNumber: null,
        issuingAuthority: null,
        validity: [],
        validityStart: null,
        validityEnd: null,
        dateOfChanges: null,
        licenceActionInfo: {},
        wastesList: [],
      },

      rules: {
        licenceActionInfo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        licenseNumber: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        registrationNumber: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        issuingAuthority: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        validity: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      organizations: "GETNATORGANIZATIONS",
      listLicenseAction: "GETLISTLICENSEACTION",
    }),
  },

  methods: {
    addLicense() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.addLicenseForm.validityStart = this.addLicenseForm.validity[0];
          this.addLicenseForm.validityEnd = this.addLicenseForm.validity[1];
          console.log(this.addLicenseForm);
          this.$store
            .dispatch("createLicense", this.addLicenseForm)
            .then((response) => {
              console.log(response);
              if (response.data.licenceId !== undefined) {
                this.notification("Успешно", "Лицензия добавлена", "success");
                this.$store.dispatch("setShowModal", null);
                this.$store.dispatch("getListAllLicenses");
              }
            });
        }
      });
    },

    getListLicenseActionData() {
      this.$store.dispatch("getListLicenseActionData");
    },
  },

  mounted() {
    this.getListLicenseActionData();
  },
};
</script>

<style scoped></style>
