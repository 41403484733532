<template>
  <div>
    <el-form
      :rules="rules"
      :model="updatePermissionForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item prop="permissionType" label="Вид разрешения">
            <el-select
              clearable
              style="width: 100%"
              class="searchPole"
              v-model="updatePermissionForm.permissionType"
              filterable
              placeholder="Вид разрешения"
            >
              <el-option
                v-for="(item, index) in permissionTypes"
                :key="index"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="permissionNumber" label="Номер разрешения:">
            <el-input
              placeholder="Номер разрешения"
              v-model="updatePermissionForm.permissionNumber"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
            prop="issuingAuthority"
            label="Орган, выдавший документ:"
          >
            <el-input
              placeholder="Номер разрешения"
              v-model="updatePermissionForm.issuingAuthority"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Срок действия</h4>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item
            prop="validityStart"
            label="Дата начала действия разрешения:"
          >
            <el-date-picker
              style="width: 100%"
              v-model="updatePermissionForm.validityStart"
              value-format="yyyy-MM-dd"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            prop="validityEnd"
            label="Дата окончания действия разрешения:"
          >
            <el-date-picker
              style="width: 100%"
              v-model="updatePermissionForm.validityEnd"
              value-format="yyyy-MM-dd"
              start-placeholder="Дата начала"
              end-placeholder="Дата окончания"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="dateOfChanges"
            label="Дата внесения изменений и/или дополнений:"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="updatePermissionForm.dateOfChanges"
              type="date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-button @click="editPermission" type="primary"
            >Сохранить</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "editPermissionForm",
  props: ["updatePermissionForm"],
  data() {
    return {
      permissionTypes: ["Хранение и захоронение отходов производства"],

      rules: {
        permissionType: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        permissionNumber: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        issuingAuthority: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        validityStart: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        validityEnd: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    editPermission() {
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("updatePermission", this.updatePermissionForm)
            .then((response) => {
              console.log(response);
              if (response.data.permissionId !== undefined) {
                this.notification("Успешно", "Разрешение изменено", "success");
                this.$store.dispatch("getListAllPermissions");
                this.$store.dispatch("setShowModal", null);
              }
            });
        }
      });
    },
  },

  mounted() {
    this.updatePermissionForm.validity = [];
    this.updatePermissionForm.validity.push(
      this.updatePermissionForm.validityStart
    );
    this.updatePermissionForm.validity.push(
      this.updatePermissionForm.validityEnd
    );
  },
};
</script>

<style scoped></style>
